import request from '@/utils/request'

// 查询公众号管理列表
export function listPublicAccount(query) {
  return request({
    url: '/work/PublicAccount/list',
    method: 'get',
    params: query
  })
}

// 查询公众号管理详细
export function getPublicAccount(id) {
  return request({
    url: '/work/PublicAccount/' + id,
    method: 'get'
  })
}

// 新增公众号管理
export function addPublicAccount(data) {
  return request({
    url: '/work/PublicAccount',
    method: 'post',
    data: data
  })
}

// 修改公众号管理
export function updatePublicAccount(data) {
  return request({
    url: '/work/PublicAccount/update',
    method: 'post',
    data: data
  })
}

// 删除公众号管理
export function delPublicAccount(id) {
  return request({
    url: '/work/PublicAccount/remove/' + id,
    method: 'get'
  })
}

// 导出公众号管理
export function exportPublicAccount(query) {
  return request({
    url: '/work/PublicAccount/export',
    method: 'get',
    params: query
  })
}